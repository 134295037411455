<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />

    <b-card>
      <b-row>
        <b-col lg="8">
          <b-form @submit="onSubmit" @reset="onReset">
            <!-- 验证码 -->
            <b-form-group
              label="Invitation Code:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="invitation-code"
            >
              <b-form-input
                id="invitation-code"
                placeholder="Automatic system generation"
                disabled
                >
              </b-form-input>
            </b-form-group>

            <!-- 备注 -->
            <b-form-group
              label="Notes"
              label-cols-lg="3"
              label-align-sm="right"
              label-for="remark"
            >
              <b-form-textarea
                id="remark"
                v-model="form.remarks"
                placeholder="Enter Notes"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group label-cols-lg="3">
              <b-button type="submit" variant="primary">Generate</b-button>
              <b-button type="reset" variant="danger" class="m-l-5 ml-1"
                >Cancel</b-button
              >
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>
    </b-card>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import { onReady, upload_img } from "@/api/upload";
import goback from "@/components/goback";

/**
 * FAQ Add component
 */
export default {
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
    goback,
  },
  data() {
    return {
      page: {
        title: "Create Invitation Code",
        items: [
          {
            text: this.$store.getters.getTitle,
            // href: "/"
          },
          {
            text: "Client Mgmt",
            // href: "/"
          },
          {
            text: "Create Invitation Code",
            active: true,
          },
        ],
      },
      form: {
        remarks: "",
      },
      editId: window.location.search,
      editor: ClassicEditor,
    };
  },
  beforeCreate() {},
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    onReady: onReady,
    init() {},
    onSubmit(evt) {
      evt.preventDefault();
      this.$api.invitationCode.invitationCodeAdd(this.form).then((res) => {
        if (res.message) {
          this.$bvToast.toast(res.message);
        }
        if (res.success) {
          this.$router.go(-1);
        }
      });
    },
    onReset(evt) {
      evt.preventDefault();
      this.$nextTick(() => {
        this.$router.go(-1);
      });
    },
  },
};
</script>
